import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Collapser from "../../../components/library/Collapser";
import { Paragraph, SubHeader, Title } from "../../../components/library/Text";
import useMobile from "../../../hooks/useMobile";
import AllIngredients from "./AllIngredients";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 55px auto 100px;

  @media screen and (max-width: 768px) {
    margin: 56px auto 69px;
  }
`;

const IngredientsCol = styled(Col)`
  padding-top: 33px;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 36px;
  }
`;

const Ingredients = styled(Collapser)`
  .collapse-wrapper {
    margin-bottom: 30px;
  }
`;

const ImageCol = styled(Col)`
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 560px;
  max-height: 560px;
  margin: 33px auto 0;

  img {
    width: 100%;
    height: 100%;
  }

  ::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media screen and (max-width: 768px) {
    max-width: 340px;
    max-height: 340px;
    margin: 0 auto;
  }
`;

const items = [
  {
    key: 7,
    text: "Coconut Extract",
    content: "Coconut extract helps prevent the loss of protein and vital moisture for your hair.",
    isOpen: false
  },
  {
    key: 8,
    text: "Bamboo Extract",
    content:
      "Bamboo extract minimizes dryness by locking in moisture and keeps your strands shining without feeling greasy.",
    isOpen: false
  },
  {
    key: 9,
    text: "Ginseng",
    content:
      "Ginseng is a lightweight, vitamin-rich oil that helps moisturize and strengthen your hair.",
    isOpen: false
  },
  {
    key: 10,
    text: "Vitamin C",
    content: "Vitamin C functions as a hair nutrient, conditioner and anti-oxidant.",
    isOpen: false
  },
  {
    key: 11,
    text: "Amino Acids",
    content:
      "Amino acids are the building blocks of proteins.  They are used to strengthen, repair and condition.",
    isOpen: false
  }
];

const ProductInfo = () => {
  const isMobile = useMobile();
  const imageAnimationOnDesktop = isMobile
    ? {}
    : {
        "data-aos": "fade-down",
        "data-aos-delay": 200
      };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <IngredientsCol md={6} id="ingredients" data-aos="fade-down">
            <Title>Ingredients</Title>
            <SubHeader>Black Hair Ready</SubHeader>
            <Paragraph>Our Curl & Coil Balm brings you powerful ingredients like:</Paragraph>
            <Ingredients disableHeightAnimation items={items} />
            {isMobile ? <AllIngredients /> : null}
          </IngredientsCol>
          <ImageCol md={6} {...imageAnimationOnDesktop}>
            <ImageContainer>
              <StaticImage
                src="https://static.wavyformula.com/images/packaging-both.jpg"
                alt="open-package"
              />
            </ImageContainer>
          </ImageCol>
        </Row>
      </Container>
      {!isMobile ? <AllIngredients /> : null}
    </Wrapper>
  );
};

export default ProductInfo;
