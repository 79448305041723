import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Collapse from "./Collapse";
import { Plus } from "./Icons";

interface CollapserProps {
  items: any[];
  className?: string;
  toggleAll?: boolean;
  toggleAllContent?: React.ReactNode;
  disableHeightAnimation?: boolean;
}

const Wrapper = styled.div<{ disableHeightAnimation: boolean }>`
  overflow: hidden;
  transition: ${props => (props.disableHeightAnimation ? undefined : "height .35s ease")};
`;

const TextContainer = styled.div`
  cursor: pointer;
  margin: 24px 0;
  display: flex;
  justify-content: space-between;

  :first-child {
    margin-top: 0;
  }
`;

const Text = styled.div`
  max-width: calc(100% - 12px);
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
`;

const IconContainer = styled.span`
  float: right;
`;

const CollapseIcon = styled(Plus)<{ $isOpen: boolean }>`
  transform: ${props => (props.$isOpen ? "rotate(45deg)" : undefined)};
  transition: transform 0.35s ease;
`;

const Content = styled.div`
  margin-bottom: 24px;
`;

const OpenAll = styled.div`
  cursor: pointer;
  color: #7ad4ff;
  font-size: 14px;
  line-height: 25px;
`;

const Underline = styled.div`
  content: "";
  border: 1px solid #7ad4ff;
  width: 93px;
  margin-top: 5px;
`;

const outerHeight = (el: HTMLElement) => {
  const style = getComputedStyle(el);
  return el.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
};

const Collapser = ({
  className,
  items,
  toggleAll = false,
  toggleAllContent,
  disableHeightAnimation = false
}: CollapserProps) => {
  // hooks
  const ref = useRef(null);
  const timeout = useRef(null);
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);
  const collapsedRef = useRef(null);
  const areAllToggled = useRef(false);
  const heightArr = useRef(items.map(() => 0));
  const [baseHeight, setBaseHeight] = useState(0);
  const [openState, setOpenState] = useState(items.map(i => Boolean(i.isOpen)));

  // update the height of the wrapper
  const computedHeight = heightArr.current.reduce(
    (pv, c, i) => pv + (openState[i] ? c : 0),
    baseHeight
  );
  const finalHeight = Math.max(computedHeight, baseHeight);
  const currentHeight = ref.current ? ref.current.clientHeight : 0;

  // update the height of this object
  let after = false;
  if (currentHeight && currentHeight !== finalHeight) {
    if (currentHeight < finalHeight) {
      ref.current.style.height = `${finalHeight}px`;
    } else {
      after = true;
    }
  }

  // functions
  const onToggle = (index: number) => {
    setOpenState(openState.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  const onToggleAll = () => {
    areAllToggled.current = !areAllToggled.current;
    setOpenState(openState.map(() => areAllToggled.current));
  };

  const onResize = useCallback(() => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      // update the height array
      const collapses = [...ref.current.querySelectorAll(".wavy-collapse")];

      // get the height of static things, like button and the wrapper offsets if present
      const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0;
      const wrapperHeight = outerHeight(wrapperRef.current);

      // disable all transitions && find the heights of everything
      let oldTotalCollapseHeight = 0;
      collapses.forEach((elem, i) => {
        // disable transition
        elem.style.transition = "none";

        // current height of collapse
        oldTotalCollapseHeight += parseInt(elem.style.height);

        // update element height
        heightArr.current[i] = [...elem.children].reduce((pv, c) => pv + outerHeight(c), 0);
        elem.style.height = `${openState[i] ? heightArr.current[i] : 0}px`;
      });

      // once that's done, re-enable transitions and update baseHeight
      const newBaseHeight = buttonHeight + wrapperHeight - oldTotalCollapseHeight;
      requestAnimationFrame(() => {
        if (baseHeight !== newBaseHeight) {
          setBaseHeight(newBaseHeight);
        }

        collapses.forEach(elem => {
          elem.style.transition = null;
        });
      });
    }, 50);
  }, [baseHeight, openState]);

  // set baseHeight
  useEffect(() => {
    setBaseHeight(ref.current.clientHeight);
  }, []);

  // if you're collapsing, try and collapse the height
  useEffect(() => {
    if (collapsedRef.current) {
      window.clearTimeout(collapsedRef.current);
    }

    if (after) {
      if (disableHeightAnimation) {
        collapsedRef.current = setTimeout(() => {
          ref.current.style.height = `${finalHeight}px`;
        }, 350);
      } else {
        ref.current.style.height = `${finalHeight}px`;
      }
    }
  }, [after, finalHeight, disableHeightAnimation]);

  // handle window resize
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <Wrapper ref={ref} className={className} disableHeightAnimation={disableHeightAnimation}>
      <div ref={wrapperRef} className="collapse-wrapper">
        {items.map((item, i) => {
          const isOpen = openState[i];

          return (
            <React.Fragment key={item.key}>
              <TextContainer onClick={() => onToggle(i)}>
                <Text>{item.text}</Text>
                <IconContainer>
                  <CollapseIcon $isOpen={isOpen} />
                </IconContainer>
              </TextContainer>
              <Collapse
                index={i}
                isOpen={isOpen}
                heightArr={heightArr.current}
                pauseHeightAnimation={disableHeightAnimation}
              >
                <Content>{item.content}</Content>
              </Collapse>
            </React.Fragment>
          );
        })}
      </div>
      {!toggleAll ? null : (
        <OpenAll ref={buttonRef} onClick={onToggleAll}>
          {toggleAllContent}
          <Underline />
        </OpenAll>
      )}
    </Wrapper>
  );
};

export default Collapser;
