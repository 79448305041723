import React, { useEffect, useRef } from "react";
import styled from "styled-components";

interface CollapseProps {
  isOpen: boolean;
  index: number;
  className?: string;
  children?: React.ReactNode;
  pauseHeightAnimation: boolean;
  heightArr: number[];
}

const Content = styled.div`
  overflow: hidden;
  transition: height 0.35s ease;
  border-bottom: 1px solid #d3d3d3;
`;

const Collapse = ({
  className,
  isOpen,
  children,
  index,
  heightArr,
  pauseHeightAnimation
}: CollapseProps) => {
  const ref = useRef(null);
  const _class = `wavy-collapse ${className || ""}`.trim();

  // update height in frame
  if (ref.current) {
    if (isOpen) {
      if (pauseHeightAnimation) {
        setTimeout(() => {
          ref.current.style.height = `${heightArr[index]}px`;
        }, 20);
      } else {
        ref.current.style.height = `${heightArr[index]}px`;
      }
    } else if (ref.current.style.height) {
      ref.current.style.height = "0px";
    }
  }

  useEffect(() => {
    const height = ref.current.scrollHeight;
    if (!heightArr[index]) {
      heightArr[index] = height;
      ref.current.style.height = "0px";
    }
  }, [index, heightArr]);

  return (
    <Content ref={ref} className={_class}>
      {children}
    </Content>
  );
};

export default Collapse;
