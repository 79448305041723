import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import LandingSection from "../routes/products/main/LandingSection";

const ProductsPage = () => (
  <Layout>
    <Seo title="Products" />
    <LandingSection />
  </Layout>
);

export default ProductsPage;
