import React, { useCallback, useContext, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/library/Button";
import { CartContext, CartProviderProps } from "../../../contexts/CartContext";
import useBuyNow from "../../../hooks/useBuyNow";

const ButtonContainer = styled.div`
  position: fixed;
  top: 10.5px;
  left: calc(50% + 75px);
  z-index: 16;
  display: flex;
  width: 100%;
  max-width: 200px;

  @media screen and (min-width: 1201px) {
    max-width: 235px;
  }

  @media screen and (max-width: 1050px) {
    max-width: 175px;
  }
`;

const BuyNowButton = styled(Button)`
  display: block;
  height: 49px;
  width: 100%;
  padding: 12px 20px;
  background-color: #4b5a62;

  @media screen and (max-width: 1200px) {
    padding: 12px;
  }
`;

const BuyNow = ({ value }: { value: number }) => {
  // hooks
  const buyNow = useBuyNow();
  const [showValue, setShowValue] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { loading } = cartContext.state;
  const sku = `${value === 1 ? "TWO" : "FOUR"}_OZ`;
  const str = !showValue ? "" : ` | $${value === 1 ? 22 : 30}`;

  // functions
  const onBuyClick = useCallback(() => {
    buyNow(sku);
  }, [buyNow, sku]);

  const onResize = useCallback(() => {
    setShowValue(window.innerWidth >= 1050);
    setShowButton(window.innerWidth > 970);
  }, []);

  // effects
  useLayoutEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return !showButton ? null : (
    <ButtonContainer>
      <BuyNowButton disabled={loading} onClick={onBuyClick}>
        {`Buy Now${str}`}
      </BuyNowButton>
    </ButtonContainer>
  );
};

export default BuyNow;
