import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Collapse from "../../../components/library/Collapse";

const Wrapper = styled.div`
  @media screen and (min-width: 769px) {
    padding-left: 15px;
  }
`;

const IngredientsButton = styled.div`
  display: inline-block;
  cursor: pointer;
  color: #a9cadd;
  font-size: 14px;
  line-height: 25px;
`;

const Underline = styled.div`
  content: "";
  border: 1px solid #a9cadd;
  width: 93px;
  margin-top: 5px;
`;

const StyledCollapse = styled(Collapse)`
  border-bottom: none;
`;

const outerHeight = el => {
  const style = getComputedStyle(el);
  return el.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
};

const AllIngredients = () => {
  const ref = useRef<HTMLDivElement>(null);
  const timeout = useRef(0);
  const heightArr = useRef([0]);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [baseHeight, setBaseHeight] = useState(0);

  // update the height of the wrapper
  const computedHeight = (isOpen ? heightArr.current[0] : 0) + baseHeight;
  const finalHeight = Math.max(computedHeight, baseHeight);
  const currentHeight = ref.current ? ref.current.clientHeight : 0;

  // update the height of this object
  let after = false;
  if (currentHeight && currentHeight !== finalHeight) {
    if (currentHeight < finalHeight && !!ref.current) {
      ref.current.style.height = `${finalHeight}px`;
    } else {
      after = true;
    }
  }

  // functions
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const onResize = useCallback(() => {
    window.clearTimeout(timeout.current);

    timeout.current = window.setTimeout(() => {
      if (!ref.current) {
        return;
      }

      // get collapse
      const collapse = [...ref.current.querySelectorAll(".wavy-collapse")][0] as HTMLElement;

      // disable transition
      collapse.style.transition = "none";

      // current height of collapse
      const oldHeight = parseInt(collapse.style.height);

      // update element height
      heightArr.current[0] = [...collapse.children].reduce((pv, c) => pv + outerHeight(c), 0);
      collapse.style.height = `${isOpen ? heightArr.current[0] : 0}px`;

      // once that's done, re-enable transitions and update baseHeight
      const newBaseHeight = (buttonRef.current?.offsetHeight || 0) + oldHeight;
      requestAnimationFrame(() => {
        if (baseHeight !== newBaseHeight) {
          setBaseHeight(newBaseHeight);
        }

        if (!!collapse) {
          collapse.style.transition = "";
        }
      });
    }, 50);
  }, [baseHeight, isOpen]);

  // set baseHeight
  useEffect(() => {
    setBaseHeight(ref.current?.clientHeight || 0);
  }, []);

  // if you're collapsing, try and collapse the height
  useEffect(() => {
    if (after && !!ref.current) {
      ref.current.style.height = `${finalHeight}px`;
    }
  }, [after, finalHeight]);

  // clear timeout on dismount
  useEffect(() => {
    return () => window.clearTimeout(timeout.current);
  }, []);

  // handle window resize
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <Wrapper ref={ref}>
      <IngredientsButton ref={buttonRef} onClick={toggle}>
        All Ingredients
        <Underline />
      </IngredientsButton>
      <StyledCollapse pauseHeightAnimation index={0} isOpen={isOpen} heightArr={heightArr.current}>
        <div>
          Water/Aqua/Eau, Cetyl Alcohol, Stearyl Alcohol, Dicetyldimonium Chloride, Bambusa Vulgaris
          (Bamboo) Extract, Caffeine, Panax Ginseng Root Extract, Cocos Nucifera (Coconut) Fruit
          Extract, Tocopheryl Acetate (Vitamin E), Retinyl Palmitate (Vitamin A), Ascorbyl Palmitate
          (Vitamin C), Panthenol (ProVitamin B5), Niacinamide (Vitamin B3), Aloe Barbadensis Leaf
          Juice, Spirulina Platensis Extract, Rosa Canina (Dog Rose) Fruit Extract, Panax
          Quinquefolius (Ginseng) Root Extract, Centella Asiatica (Gotu Kola) Extract,
          Phospholipids, Sodium PCA, Sodium Lactate, Amino Acids: [Arginine, Aspartic Acid, PCA,
          Glycine, Alanine, Serine, Valine, Proline, Threonine, Isoleucine, Histidine,
          Phenylalanine], Hydroxyethylcellulose, Glycerin, PEG-90M, Methyl Gluceth-10, Cetrimonium
          Chloride, Dimethicone, Sorbic Acid, Salicylic Acid, Fragrance/Parfum, Sodium Hydroxide,
          Citric Acid, Methylisothiazolinone, Phenoxyethanol.
        </div>
      </StyledCollapse>
    </Wrapper>
  );
};

export default AllIngredients;
