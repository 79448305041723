import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Button } from "../../../components/library/Button";
import PictureCarousel from "../../../components/library/PictureCarousel";
import { Paragraph, SubHeader, Title } from "../../../components/library/Text";
import Ocean from "../../../components/ocean/Ocean";
import BrowserConstants from "../../../constants/Browser";
import { ImageConstants } from "../../../constants/Images";
import { CartContext, CartProviderProps } from "../../../contexts/CartContext";
import useBuyNow from "../../../hooks/useBuyNow";
import useMobile from "../../../hooks/useMobile";
import NextIcon from "../../../images/next-product.svg";
import PrevIcon from "../../../images/prev-product.svg";
import BuyNow from "./BuyNow";
import ProductInfo from "./ProductInfo";

const Section = styled.section`
  position: relative;
  padding: 124px 0 194px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  ::after {
    content: "";
    height: calc(100% + 70px);
    width: 100%;
    position: absolute;
    top: -70px;
    left: 0;
    background-color: #bce0f6;
    z-index: -1;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const Wrapper = styled(Container)`
  position: relative;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
`;

const InfoColumn = styled(Col)`
  @media screen and (width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const ProductColumn = styled(InfoColumn)`
  ::after {
    content: "";
    background-color: #c5def5;
    height: calc(100% + 70px);
    width: 100%;
    position: absolute;
    top: -70px;
    left: 0;
    z-index: -1;
  }
`;

const PackageCarousel = styled(PictureCarousel)`
  img {
    margin: 0;
    height: 400px;
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    height: 364px;

    .carousel-item {
      height: 294px;
    }

    .carousel-indicators {
      bottom: 60px;
    }
    img {
      height: calc(100% - 50px);
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding-left: 85px;

  @media screen and (max-width: 768px) {
    padding-top: 36px;
    padding-left: 0;
    text-align: center;
  }
`;

const MainTitle = styled(Title)`
  margin-bottom: 12px;
  color: rgba(9, 12, 14, 0.5);
`;

const MainSubHeader = styled(SubHeader)`
  margin-bottom: 17px;
`;

const Helper = styled.div`
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 5.5px 11.5px;
  width: 222px;

  /* font */
  font-family: "Gill Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.34);

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Info = styled(Paragraph)`
  margin-bottom: 54px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const BuyNowButton = styled(Button)`
  height: 49px;
  width: 235px;
  margin-right: 17px;
  padding: 12px 20px;
  background-color: #4b5a62;

  @media screen and (max-width: 768px) {
    color: #4b5a62;
    background-color: #bce0f6;

    :hover {
      color: white;
      background-color: #4b5a62;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 900px) {
    width: 60%;
    font-size: 12px;
    padding: 12px 0;
  }
`;

const Sentinel = styled.div`
  position: absolute;
  bottom: 0;
  height: 119px;
  width: 10px;
  z-index: -1;
`;

const OceanWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  @media screen and (max-width: 768px) {
    width: calc(100% + 15px);
    left: -15px;
  }
`;

const travelSlides = [
  {
    key: 1,
    altText: "Slide 1",
    src: "https://static.wavyformula.com/images/stacked-jars-2.png",
    useWebp: true
  },
  {
    key: 2,
    altText: "Slide 2",
    src: "https://static.wavyformula.com/images/travel-jar.png"
  },
  {
    key: 3,
    altText: "Slide 3",
    src: ImageConstants.TRAVEL_TOP
  }
];

const fullSlides = [
  // {
  //   key: 1,
  //   altText: "Slide 1",
  //   src: "https://static.wavyformula.com/images/stacked-jars-2.png",
  //   useWebp: true
  // },
  {
    key: 2,
    altText: "Slide 2",
    src: "https://static.wavyformula.com/images/full-jar.png"
  },
  {
    key: 3,
    altText: "Slide 3",
    src: ImageConstants.FULL_TOP
  }
];

const LandingSection = () => {
  // hooks
  const buyNow = useBuyNow();
  const isMobile = useMobile();
  const sentinelRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [value, setValue] = useState(2);
  const [buyIsVisible, setBuyIsVisible] = useState(false);
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { loading } = cartContext.state;
  const sku = `${value === 1 ? "TWO" : "FOUR"}_OZ`;
  const price = value === 1 ? 22 : 30;
  const items = value === 1 ? travelSlides : fullSlides;
  const areButtonsVisible = !isMobile && buyIsVisible;

  // functions
  const onClick = useCallback(option => setValue(option.key), []);
  const onBuyClick = useCallback(() => {
    buyNow(sku);
  }, [buyNow, sku]);

  // effects
  // sticky buy button in menu
  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        // only run on desktop
        if (window.innerWidth <= 970) {
          if (buyIsVisible) {
            setBuyIsVisible(false);
          }
          return;
        }

        const isVisible = entry.intersectionRatio < 0.58 && window.scrollY >= 40;
        if ((isVisible && !buyIsVisible) || (!isVisible && buyIsVisible)) {
          setBuyIsVisible(isVisible);
        }
      },
      { threshold: BrowserConstants.THRESHOLDS }
    );

    // observe && disconnect
    if (!!sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }
    return () => {
      if (!!observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [buyIsVisible]);

  // return
  return (
    <>
      <Section>
        <Wrapper fluid>
          <Row>
            <ProductColumn md={6}>
              <PackageCarousel items={items} prevIcon={PrevIcon} nextIcon={NextIcon} />
              {!isMobile ? null : (
                <OceanWrapper>
                  <Ocean />
                </OceanWrapper>
              )}
            </ProductColumn>
            <InfoColumn md={6}>
              <Content>
                <MainTitle>Designed for you</MainTitle>
                <MainSubHeader>Curl & Coil Balm</MainSubHeader>
                <Helper>Available in 4 oz. jar</Helper>
                <Info>
                  Your hair deserves better. Our lightweight balm is designed to effortlessly
                  hydrate, condition and style through black hair ready ingredients like coconut
                  extract, bamboo and ginseng. An easy addition to any routine, our Curl & Coil Balm
                  will get you closer to that fresh cut feel. Bonus? It smells great too.
                </Info>
                <ButtonContainer>
                  <BuyNowButton disabled={loading} onClick={onBuyClick}>
                    Buy Now | ${price}
                  </BuyNowButton>
                  {/* <SizeDropdown value={value} onClick={onClick} /> */}
                </ButtonContainer>
                <Sentinel ref={sentinelRef} />
                {areButtonsVisible ? <BuyNow value={value} /> : null}
                {/* <PreOrderInfo /> */}
              </Content>
            </InfoColumn>
          </Row>
        </Wrapper>
        {isMobile ? null : (
          <OceanWrapper>
            <Ocean />
          </OceanWrapper>
        )}
      </Section>
      <ProductInfo />
    </>
  );
};

export default LandingSection;
