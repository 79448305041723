export const getFileParts = (url: string) => {
  const typeMatch = url.match(/\.([^.]*)$/);
  if (!typeMatch) {
    throw new Error("url is not a file");
  }

  const extension = typeMatch[1].toLowerCase();
  if (!extension) {
    throw new Error("could not determine image type");
  }

  return {
    base: url.replace(/\.([^.]*)$/, ""),
    extension
  };
};
